<template>
  <div class="informatization">
    <div class="informatization-title">
      <div style="width: 437px">
        <div class="informatization-text">信息化产品服务</div>
        <div class="english">INFORMATION SERVICES</div>
      </div>
    </div>
    <div class="title-bottom">
      我们能为您提供一系列专业、可靠、前沿的信息化解决方案
    </div>
    <div class="content">
      <div class="content-left">
        <div
          class="content-left-nav"
          :class="{ selected: navId === item.id }"
          v-for="(item, index) in navData"
          :key="index"
          @click="navId = item.id"
        >
          <img style="width: 66px; height: 66px" :src="item.icon" alt="" />
          <div>
            {{ item.title }}
          </div>
        </div>
      </div>
      <div class="content-right">
        <div v-if="navId == 1">
          <div class="right-top">
            <div style="display: flex">
              <div>
                <div class="top-title">
                  <img
                    style="width: 40px; height: 31px"
                    src="../../assets/informatization/5.png"
                    alt=""
                  />
                  <div>服务概括</div>
                </div>
                <div class="top-txt">
                  &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp教育信息化是教育发展的必然趋势，是将现代信息技术应用于教育领域、教育管理、教育教学和研究工作中，以推动教育改革和发展的一个过程。它的技术特征是数字化，网络化，智能化，多媒介化,以信息化推动教育的现代化，以信息技术改造传统的教学方式。
                </div>
              </div>
              <div style="width: 178px"></div>
            </div>
            <img
              class="right-img"
              style="width: 190px; hight: 190px"
              src="../../assets/informatization/6.png"
              alt=""
            />
          </div>
          <div style="height: 40px">
            <!-- 查看更多产品<i class="el-icon-arrow-right"></i> -->
          </div>
          <div class="right-content">
            <div class="right-middle">
              <div class="middle">
                <div
                  style="
                    text-align: left;
                    font-size: 24px;
                    color: rgba(0, 0, 0, 1);
                    margin-bottom: 20px;
                  "
                >
                  一体化智慧校园
                </div>
                <div
                  style="
                    text-align: left;
                    line-height: 23px;
                    color: rgba(166, 168, 182, 1);
                  "
                >
                  一体化智慧校园解决方案充分应用物联网技术、大数据技术、人工智能技术...
                </div>
                <div @click="goWisdom" class="click-txt">
                  了解详情<i class="el-icon-arrow-right"></i>
                </div>
              </div>
              <div style="width: 20px"></div>
              <div class="middle">
                <div
                  style="
                    text-align: left;
                    font-size: 24px;
                    color: rgba(0, 0, 0, 1);
                    margin-bottom: 20px;
                  "
                >
                  人才培养大数据分析平台
                </div>
                <div
                  style="
                    text-align: left;
                    line-height: 23px;
                    color: rgba(166, 168, 182, 1);
                  "
                >
                  实现高校思政育人工作从入校到离校的管理全程化，让高校育人工作贯穿学生在校期间整个学习...
                </div>
                <div @click="goCabs" class="click-txt">
                  了解详情<i class="el-icon-arrow-right"></i>
                </div>
              </div>
            </div>
            <div class="right-bottom">
              <div class="middle">
                <div
                  style="
                    text-align: left;
                    font-size: 24px;
                    color: rgba(0, 0, 0, 1);
                    margin-bottom: 20px;
                  "
                >
                  教育教学质量评估平台
                </div>
                <div
                  style="
                    text-align: left;
                    line-height: 23px;
                    color: rgba(166, 168, 182, 1);
                  "
                >
                  教育教学质量评估平台通过采集学校的教学基本状态数据，并以数据可视化的形式反映出来...
                </div>
                <div @click="goEducation" class="click-txt">
                  了解详情<i class="el-icon-arrow-right"></i>
                </div>
              </div>
              <div style="width: 20px"></div>
              <div class="middle">
                <div
                  style="
                    text-align: left;
                    font-size: 24px;
                    color: rgba(0, 0, 0, 1);
                    margin-bottom: 20px;
                  "
                >
                  思政教育智慧平台
                </div>
                <div
                  style="
                    text-align: left;
                    line-height: 23px;
                    color: rgba(166, 168, 182, 1);
                  "
                >
                  建设一个基于大数据的智慧学工平台，解决辅导员对学生的发展难、评价、干预难及时两大问题
                </div>
                <div @click="goPolitical" class="click-txt">
                  了解详情<i class="el-icon-arrow-right"></i>
                </div>
              </div>
            </div>
            <div class="right-bottom">
              <div class="middle">
                <div
                  style="
                    text-align: left;
                    font-size: 24px;
                    color: rgba(0, 0, 0, 1);
                    margin-bottom: 20px;
                  "
                >
                  专业建设质量评估平台
                </div>
                <div
                  style="
                    text-align: left;
                    line-height: 23px;
                    color: rgba(166, 168, 182, 1);
                  "
                >
                  专业建设质量评估平台，通过教育教学数据收集、分析、反馈等...
                </div>
                <div @click="goMajor" class="click-txt">
                  了解详情<i class="el-icon-arrow-right"></i>
                </div>
              </div>
              <div style="width: 20px"></div>
              <div class="middle">
                <div
                  style="
                    text-align: left;
                    font-size: 24px;
                    color: rgba(0, 0, 0, 1);
                    margin-bottom: 20px;
                  "
                >
                  虚拟仿真实训室建设
                </div>
                <div
                  style="
                    text-align: left;
                    line-height: 23px;
                    color: rgba(166, 168, 182, 1);
                  "
                >
                  遵循“虚实结合、以虚补实、以虚促实”的原则，按照实际情况设计拟仿真实训室解决方案...
                </div>
                <div @click="goTraining" class="click-txt">
                  了解详情<i class="el-icon-arrow-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="navId == 2">
          <Military></Military>
        </div>
        <div v-if="navId == 3">
          <Affairs></Affairs>
        </div>
        <div v-if="navId == 4">
          <Other></Other>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Military from "./components/military.vue";
import Affairs from "./components/affairs.vue";
import Other from "./components/other.vue";
export default {
  components: { Military, Affairs, Other },
  name: "Informatization",
  data() {
    return {
      navData: [
        {
          icon: require("@/assets/informatization/3.png"),
          title: "教育信息化服务",
          id: 1,
        },
        {
          icon: require("@/assets/informatization/1.png"),
          title: "军工信息化服务",
          id: 2,
        },
        {
          icon: require("@/assets/informatization/4.png"),
          title: "政企信息化服务",
          id: 3,
        },
        {
          icon: require("@/assets/informatization/2.png"),
          title: "其他信息化服务",
          id: 4,
        },
      ],
      navId: 1,
    };
  },
  methods: {
    //跳转CABS页面
    goCabs() {
      this.$router.push("/cabs").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },
    // 跳转思政页面
    goPolitical() {
      this.$router.push("/political").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },
    // 跳转智慧校园页面
    goWisdom() {
      this.$router.push("/wisdom").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },
    // 跳转虚拟仿真实训室页面
    goTraining() {
      this.$router.push("/training").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },
    // 跳转教育教学质量评估平台
    goEducation() {
      this.$router.push("/education").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },
    // 跳转专业建设指令评估平台
    goMajor() {
      this.$router.push("/major").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.informatization {
  text-align: center;
  padding: 50px 0;
}

.informatization-title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 80px;

  .informatization-line {
    display: flex;
  }

  .informatization-text {
    font-size: 36px;
    color: rgba(0, 0, 0, 1);
    font-weight: 550;
    // margin: 0 50px;
    text-align: center;
    position: relative;
    // margin-top: 24px;
    margin-bottom: -24px;
    z-index: 2;
  }

  .english {
    font-size: 36px;
    color: rgba(240, 240, 240, 1);
    font-weight: 500;
    text-align: center;
    position: relative;
    z-index: 1;
  }
}

.title-bottom {
  color: rgba(166, 168, 182, 1);
  font-size: 16px;
  margin-bottom: 80px;
}

.content {
  display: flex;
  justify-content: center;

  .content-left {
    .selected {
      border-radius: 12px;
      background: rgba(246, 248, 250, 1);
      box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
    }
    .content-left-nav {
      display: flex;
      justify-content: center;
      padding: 20px 40px;
      width: 340px;
      height: 100px;
      align-items: center;
      margin-right: 80px;
      margin-bottom: 20px;

      img {
        margin-right: 20px;
      }
      div {
        line-height: 66px;
        color: rgba(74, 74, 74, 1);
        font-size: 24px;
      }
    }
  }

  .content-right {
    width: 936px;
    height: 700px;

    .right-top {
      width: 856px;
      position: relative;
      background: rgba(247, 250, 255, 1);
      border-radius: 16px;
      padding: 10px 40px 40px 40px;

      .top-title {
        display: flex;
        justify-content: center;
        font-size: 24px;
        color: rgba(66, 66, 66, 1);
        margin-bottom: 20px;
      }
      .top-txt {
        display: flex;
        text-align: left;
        color: rgba(66, 66, 66, 1);
        line-height: 30px;
        font-size: 18px;
      }
      .right-img {
        position: absolute;
        right: -5%;
        top: -80px;
      }
    }

    .right-content {
      overflow: auto;
      height: 430px;
      width: 960px;
    }

    /* 滚动条整体样式 */
    .right-content::-webkit-scrollbar {
      width: 10px; /* 滚动条的宽度 */
    }

    /* 滚动条滑块样式 */
    .right-content::-webkit-scrollbar-thumb {
      background: #cbcaca; /* 滑块颜色 */
      border-radius: 10px; /* 滑块圆角 */
    }

    /* 滚动条轨道样式 */
    .right-content::-webkit-scrollbar-track {
      background: #f1f1f1; /* 轨道颜色 */
      border-radius: 10px; /* 轨道圆角 */
    }

    /* 当鼠标悬停在滑块上时 */
    .right-content::-webkit-scrollbar-thumb:hover {
      background: #555; /* 滑块悬停颜色 */
    }

    .right-middle {
      display: flex;
      justify-content: space-between;
      width: 936px;
    }

    .right-bottom {
      display: flex;
      justify-content: space-between;
      width: 936px;
    }
  }

  .middle {
    background: rgba(247, 250, 255, 1);
    border-radius: 16px;
    padding: 20px 40px;
    width: 425px;
    margin-bottom: 40px;
  }

  .click-txt {
    color: rgba(0, 113, 189, 1);
    text-align: right;
    padding: 10px 0;
    cursor: pointer;
  }
}
</style>
