<template>
  <div class="technology">
    <div class="technology-title">
      <div class="technology-line">
        <div class="line"></div>
        <div class="block"></div>
      </div>
      <div style="margin: 0 20px; width: 437px">
        <div class="technology-text">技术支持</div>
        <div class="english">TECHNICAL SUPPORT</div>
      </div>
      <div class="technology-line">
        <div class="block"></div>
        <div class="line"></div>
      </div>
    </div>
    <div style="margin-bottom: 40px; margin: 20px 0; color: #c4c4c4">
      每天有超过1000个终端用户使用
    </div>
    <div style="margin: 0 auto; padding-top: 80px">
      <div style="display: flex; justify-content: center">
        <el-row class="technology-content">
          <el-col class="content">
            <img src="../../assets/technology/1.png" alt="" />
            <div>
              <div class="content-top">数据采集</div>
              <div class="content-bottom">大数据采集、分析呈现、清晰直观</div>
            </div>
          </el-col>
          <el-col class="content" style="margin: 0 5vw">
            <img src="../../assets/technology/2.png" alt="" />
            <div>
              <div class="content-top">7*24小时全天候技术支持</div>
              <div class="content-bottom">承诺3小时响应，快速到达现场</div>
            </div>
          </el-col>
          <el-col class="content">
            <img src="../../assets/technology/3.png" alt="" />
            <div>
              <div class="content-top">功能培训</div>
              <div class="content-bottom">
                专业培训人员量身定制用户需求培训内容
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div style="display: flex; justify-content: center">
        <el-row class="technology-content">
          <el-col class="content">
            <img src="../../assets/technology/4.png" alt="" />
            <div>
              <div class="content-top">口碑优质</div>
              <div class="content-bottom">
                合作高等院校、军工、政企单位100多家，客户满意度高
              </div>
            </div>
          </el-col>
          <el-col class="content" style="margin: 0 5vw">
            <img src="../../assets/technology/5.png" alt="" />
            <div>
              <div class="content-top">定制服务</div>
              <div class="content-bottom">定制满足用户需求的功能、产品</div>
            </div>
          </el-col>
          <el-col class="content">
            <img src="../../assets/technology/6.png" alt="" />
            <div>
              <div class="content-top">软件更新</div>
              <div class="content-bottom">开发及运营团队保证软件的更新迭代</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Technology",
};
</script>

<style lang="scss" scoped>
.technology {
  text-align: center;
  background: url("../../assets/technology.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 50px 0;
  height: 850px;
}
.technology-title {
  padding-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  .technology-line {
    display: flex;
  }

  .line {
    width: 460px;
    text-align: center;
    height: 1.5px;
    background: #fff;
    transform: translateY(15px);
  }

  .block {
    width: 10px;
    height: 10px;
    background: #fff;
    margin-top: -5px;
    transform: translateY(15px);
  }

  .technology-text {
    font-size: 36px;
    color: #fff;
    margin: 0 50px;
    font-weight: 550;
    position: relative;
    margin-bottom: -24px;
    z-index: 2;
    text-align: center;
  }

  .english {
    font-size: 36px;
    color: rgb(73, 86, 104);
    font-weight: 500;
    text-align: center;
    position: relative;
    z-index: 1;
  }
}

.technology-content {
  width: 1420px;
  display: flex;
  justify-content: center;
  margin: 80px 0;

  img {
    width: 50px;
    height: 50px;
    margin-right: 2vw;
  }

  .content {
    display: flex;
    text-align: left;
  }
}

.content-top {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 20px;
}

.content-bottom {
  font-size: 16px;
  color: #9a9a9a;
  text-align: left;
}
</style>
