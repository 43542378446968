<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  components: {},
  data() {
    return {
      thresholdWidth: 2300, // 设定阈值宽度
      shouldRefresh: false, // 是否应该刷新页面的标志位
      pendingRefresh: null, // 用于存储待处理的刷新操作
    };
  },
  mounted() {
    // 获取页面初始化可视化宽度
    window.addEventListener("resize", this.handleResize);
    // 整体缩放
    window.addEventListener("resize", this.bodyScale, false);
    this.bodyScale();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      const currentWidth = window.innerWidth;
      if (currentWidth > this.thresholdWidth && !this.shouldRefresh) {
        this.shouldRefresh = true; // 设置标志位为true，表示应该刷新
        this.pendingRefresh = this.refreshPage().finally(() => {
          this.shouldRefresh = false; // 刷新完成后重置标志位
          this.pendingRefresh = null; // 重置待处理的刷新操作
        });
      }
    },
    async refreshPage() {
      if (this.pendingRefresh) {
        // 如果存在待处理的刷新操作，等待它完成
        await this.pendingRefresh;
      } else {
        // 否则，执行刷新操作
        location.reload();
      }
    },

    // 等比例缩放的方法
    bodyScale() {
      var deviceWidth = document.documentElement.clientWidth; //获取当前分辨率下的可是区域宽度
      var scale = deviceWidth / 1920; // 分母——设计稿的尺寸
      document.documentElement.style.zoom = scale; //放大缩小相应倍数
    },
  },
};
</script>

<style>
body {
  margin: 0px;
}
#app {
  overflow-x: hidden;
  /* font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* text-align: center;
  color: #2c3e50; */
  /* margin-top: 60px; */
  /* 整体页面大小缩放设置 */
  /* zoom: 0.725; */
  /* transform: scale(0.7);
  transform-origin: 0px 0px;
  width: 143%; */
}
</style>
