<template>
  <div>
    <div class="top">
      <div style="display: flex">
        <div>
          <div class="title">
            <img
              style="width: 40px; height: 31px"
              src="../../../assets/informatization/5.png"
              alt=""
            />
            <div>服务概括</div>
          </div>
          <div class="txt">
            &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp以新一代信息技术为支撑，结合行业发展特性及工作的需求，个性化设计开发了多个应用系统，实现行业需求与信息化的高度融合发展，不断为行业提供优质信息化服务，持续推进智慧型院所发展。
          </div>
        </div>
        <div style="width: 220px"></div>
      </div>
      <img
        class="right-img"
        style="width: 190px; hight: 190px"
        src="../../../assets/informatization/7.png"
        alt=""
      />
    </div>
    <div class="right-middle">
      <div class="middle">
        <div
          style="
            text-align: left;
            font-size: 24px;
            color: rgba(0, 0, 0, 1);
            margin-bottom: 20px;
          "
        >
          固件仿真技术
        </div>
        <div
          style="
            text-align: left;
            line-height: 23px;
            color: rgba(166, 168, 182, 1);
          "
        >
          通过利用成熟的仿真工具或框架，模拟运行固件中的全部或特定服务，以提供与真实设备服务...
        </div>
        <div @click="goMilitary" class="click-txt">
          了解详情<i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div style="width: 20px"></div>
      <div class="middle">
        <div
          style="
            text-align: left;
            font-size: 24px;
            color: rgba(0, 0, 0, 1);
            margin-bottom: 20px;
          "
        >
          一体化测试平台
        </div>
        <div
          style="
            text-align: left;
            line-height: 23px;
            color: rgba(166, 168, 182, 1);
          "
        >
          平台通过结合版本管理工具与各种软件测试工具的功能集成，实现软件测试...
        </div>
        <div @click="goMilitary" class="click-txt">
          了解详情<i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>
    <div style="display: flex">
      <div class="bottom">
        <div>
          <div
            style="
              text-align: left;
              font-size: 24px;
              color: rgba(0, 0, 0, 1);
              margin-bottom: 20px;
            "
          >
            综合效能评估系统
          </div>
          <div
            style="
              text-align: left;
              line-height: 23px;
              color: rgba(166, 168, 182, 1);
            "
          >
            系统基于先进的数据分析技术和算法，开展体系效能评估，实现指标体系管理...
          </div>
          <div @click="goMilitary" class="click-txt">
            了解详情<i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
      <div style="width: 20px"></div>
      <div class="bottom">
        <div>
          <div
            @click="goMilitary"
            style="
              text-align: center;
              color: rgba(166, 168, 182, 1);
              font-size: 25px;
              padding-top: 45px;
              cursor: pointer;
            "
          >
            更多平台...
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    // 跳转军工页面
    goMilitary() {
      this.$router.push("/military").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  width: 856px;
  position: relative;
  background: rgba(247, 250, 255, 1);
  border-radius: 30px;
  padding: 10px 40px 40px 40px;
  margin-bottom: 40px;

  .title {
    display: flex;
    justify-content: center;
    font-size: 24px;
    color: rgba(66, 66, 66, 1);
    margin-bottom: 20px;
  }

  .txt {
    display: flex;
    text-align: left;
    color: rgba(66, 66, 66, 1);
    line-height: 30px;
    font-size: 18px;
  }

  .right-img {
    position: absolute;
    right: -5%;
    top: -80px;
  }
}

.right-middle {
  display: flex;
  justify-content: space-between;
  width: 936px;
}

.middle {
  background: rgba(247, 250, 255, 1);
  border-radius: 30px;
  padding: 20px 40px;
  width: 425px;
  margin-bottom: 40px;
}

.bottom {
  background: rgba(247, 250, 255, 1);
  border-radius: 30px;
  padding: 20px 40px;
  width: 380px;
}

.click-txt {
  color: rgba(0, 113, 189, 1);
  text-align: right;
  padding: 10px 0;
  cursor: pointer;
}
</style>
