import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  // 首页
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutView.vue"),
  },
  // 关于宜本
  {
    path: "/intro",
    name: "intro",
    component: () => import("../views/intro/index.vue"),
  },
  // 新闻及子页面
  {
    path: "/news",
    name: "news",
    component: () => import("../views/newsDetails/index.vue"),
  },

  {
    path: "/newsThree",
    name: "newsThree",
    component: () => import("../views/newsDetails/components/three.vue"),
  },
  {
    path: "/spring",
    name: "spring",
    component: () => import("../views/newsDetails/components/spring.vue"),
  },
  // 上面是新闻
  // cabs子页面
  {
    path: "/cabs",
    name: "cabs",
    component: () => import("../views/cabs/index.vue"),
  },
  // 智慧校园
  {
    path: "/wisdom",
    name: "wisdom",
    component: () => import("../views/wisdom/index.vue"),
  },
  // 思政平台
  {
    path: "/political",
    name: "political",
    component: () => import("../views/political/index.vue"),
  },
  // 虚拟仿真实训室
  {
    path: "/training",
    name: "training",
    component: () => import("../views/training/index.vue"),
  },
  // 课堂教学平台
  {
    path: "/classroom",
    name: "classroom",
    component: () => import("../views/classroom/index.vue"),
  },
  // 运维服务
  {
    path: "/maintain",
    name: "maintain",
    component: () => import("../views/maintain/index.vue"),
  },
  // 软件定制
  {
    path: "/custom",
    name: "custom",
    component: () => import("../views/custom/index.vue"),
  },
  // 教育内涵建设
  {
    path: "/connotation",
    name: "connotation",
    component: () => import("../views/connotation/index.vue"),
  },
  // 教育教学质量评估平台
  {
    path: "/education",
    name: "education",
    component: () => import("../views/education/index.vue"),
  },
  // 政企信息化服务
  {
    path: "/government",
    name: "government",
    component: () => import("../views/government/index.vue"),
  },
  // 专业建设质量评估平台
  {
    path: "/major",
    name: "major",
    component: () => import("../views/major/index.vue"),
  },
  // 军工信息化服务
  {
    path: "/military",
    name: "military",
    component: () => import("../views/military/index.vue"),
  },
  // 其他信息化服务
  {
    path: "/other",
    name: "other",
    component: () => import("../views/other/index.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to, from, next) => {
  window, scrollTo(0, 0);
});

export default router;
