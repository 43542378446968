<template>
  <div>
    <div class="top">
      <div style="display: flex">
        <div>
          <div class="title">
            <img
              style="width: 40px; height: 31px"
              src="../../../assets/informatization/5.png"
              alt=""
            />
            <div>服务概括</div>
          </div>
          <div class="txt">
            &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp
            宜本科技秉承“科技赋能，数智化助力行业高质量发展”经营理念，通过运用专业的技术，满足多行业的信息化发展需求，实现资源共享与协同发展，共同达成可持续发展目标。
          </div>
        </div>
        <div style="width: 220px"></div>
      </div>
      <img
        class="right-img"
        style="width: 190px; hight: 190px"
        src="../../../assets/informatization/9.png"
        alt=""
      />
    </div>
    <div class="right-middle">
      <div class="middle">
        <div
          style="
            text-align: left;
            font-size: 24px;
            color: rgba(0, 0, 0, 1);
            margin-bottom: 20px;
          "
        >
          运维服务
        </div>
        <div
          style="
            text-align: left;
            line-height: 23px;
            color: rgba(166, 168, 182, 1);
          "
        >
          以客户业务和管理稳定运营为目标，实现运维规划设计与落地；以客户业务和管理提升为导向...
        </div>
        <div @click="goMaintain" class="click-txt">
          了解详情<i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div style="width: 20px"></div>
      <div class="middle">
        <div
          style="
            text-align: left;
            font-size: 24px;
            color: rgba(0, 0, 0, 1);
            margin-bottom: 20px;
          "
        >
          软件定制
        </div>
        <div
          style="
            text-align: left;
            line-height: 23px;
            color: rgba(166, 168, 182, 1);
          "
        >
          根据机构或企业的社会属性、行业属性、业务需求等，开发专属、独立、个性化的软件平台...
        </div>
        <div @click="goCustom" class="click-txt">
          了解详情<i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    // 跳转运维服务
    goMaintain() {
      this.$router.push("/maintain").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },
    // 跳转软件定制服务
    goCustom() {
      this.$router.push("/custom").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  width: 856px;
  position: relative;
  background: rgba(247, 250, 255, 1);
  border-radius: 30px;
  padding: 10px 40px 40px 40px;
  margin-bottom: 40px;

  .title {
    display: flex;
    justify-content: center;
    font-size: 24px;
    color: rgba(66, 66, 66, 1);
    margin-bottom: 20px;
  }

  .txt {
    display: flex;
    text-align: left;
    color: rgba(66, 66, 66, 1);
    line-height: 30px;
    font-size: 18px;
  }

  .right-img {
    position: absolute;
    right: -5%;
    top: -80px;
  }
}

.right-middle {
  display: flex;
  justify-content: space-between;
  width: 936px;
}

.middle {
  background: rgba(247, 250, 255, 1);
  border-radius: 30px;
  padding: 20px 40px;
  width: 425px;
  margin-bottom: 40px;
}

.click-txt {
  color: rgba(0, 113, 189, 1);
  text-align: right;
  padding: 10px 0;
  cursor: pointer;
}
</style>
