<template>
  <div class="news">
    <div class="news-title">
      <div class="news-line">
        <div class="line"></div>
        <div class="block"></div>
      </div>
      <div style="margin: 0 20px; width: 437px">
        <div class="news-text">新闻动态</div>
        <div class="english">NEWS UPDATES</div>
      </div>
      <div class="news-line">
        <div class="block"></div>
        <div class="line"></div>
      </div>
    </div>
    <div style="margin: 20px 0 40px 0; color: #999999">
      掌握实时政策动向，及时洞悉最新动态
    </div>
    <div style="width: 1420px; margin: 0 auto; padding-top: 80px">
      <el-carousel :interval="4000" type="card" height="700px">
        <el-carousel-item v-for="(item, index) in newsData" :key="index">
          <div class="medium" @click="goSpring(item.id)">
            <img
              style="width: 100%; margin-bottom: 60px"
              :src="item.wzfm"
              alt=""
            />
            <div class="carousel-title">{{ item.xwbt }}</div>
            <div class="carousel-txt">
              <div>{{ item.fbrq }}</div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div>
        <el-button
          @click="goNews"
          style="
            height: 56px;
            width: 192px;
            font-size: 16px;
            border-radius: 30px;
          "
          type="primary"
          >更多新闻动态&nbsp&nbsp&nbsp&nbsp<i class="el-icon-right"></i
        ></el-button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "News",
  data() {
    return {
      newsData: [],
      pagingQuery: {
        currentPage: 1,
        // keywords: "0",
        pageSize: 4,
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    // 跳转子页面
    goSpring(id) {
      this.$router
        .push({
          path: "/spring",
          query: { id: id },
        })
        .catch((err) => {
          if (!err.name === "NavigationDuplicated") {
          }
        });
    },
    // 获取后端数据
    getData() {
      const prom = this.pagingQuery;
      this.axios
        .post("https://www.myebkj.com/backend/xwXwzx", prom)
        .then((res) => {
          this.newsData = res.data.object.rows.map((element) => {
            element.fbrq = moment(element.fbrq).format("YYYY-MM-DD");
            return element;
          });
        });
    },
    // 跳转以数字化开辟发展新赛道子页面
    goOne() {
      this.$router.push("/newsOne").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },
    // 跳转建设较高国际化水平职业学校申报指南
    goTwo() {
      this.$router.push("/newsTwo").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },
    // 跳转职业教育积极“拥抱”数字化
    goThree() {
      this.$router.push("/newsThree").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },
    //跳转新闻页面
    goNews() {
      this.$router.push("/news").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.news {
  background: url("../../assets/news/2.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.el-carousel__item div {
  // color: #475669;
  // font-size: 14px;
  // opacity: 0.75;
  // line-height: 500px;
  // margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #fff;
  border: 1px solid rgba(207, 207, 207, 1);
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #fff;
  border: 1px solid rgba(207, 207, 207, 1);
}
</style>

<style lang="scss" scoped>
.news {
  text-align: center;
  padding: 50px 0;
}

.news-title {
  padding-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  .news-line {
    display: flex;
  }

  .line {
    width: 28vw;
    text-align: center;
    height: 1.5px;
    background: rgba(230, 230, 230, 1);
    transform: translateY(15px);
  }

  .block {
    width: 10px;
    height: 10px;
    background: rgba(204, 204, 204, 1);
    margin-top: -5px;
    transform: translateY(15px);
  }

  .news-text {
    font-size: 36px;
    color: rgba(0, 0, 0, 1);
    font-weight: 550;
    margin: 0 50px;
    text-align: center;
    position: relative;
    margin-bottom: -24px;
    z-index: 2;
  }

  .english {
    font-size: 36px;
    color: rgb(232, 230, 230);
    font-weight: 500;
    text-align: center;
    position: relative;
    z-index: 1;
  }
}

.carousel-title {
  font-size: 30px;
  color: rgba(51, 51, 51, 1);
  margin-bottom: 100px;
}

.carousel-txt {
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
}
</style>
