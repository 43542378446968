<template>
  <div>
    <Header></Header>
    <Informatization></Informatization>
    <Technology></Technology>
    <News></News>
    <Foot></Foot>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import Header from "@/views/header/index.vue";
import Technology from "../views/technology/index.vue";
import Informatization from "../views/informatization/index.vue";
import News from "../views/news/index.vue";
import Foot from "../views/foot/index.vue";

export default {
  name: "HomeView",
  components: {
    // HelloWorld
    Header,
    Technology,
    Informatization,
    News,
    Foot,
  },
  data() {
    return {};
  },
};
</script>
