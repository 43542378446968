<template>
  <div>
    <div class="top">
      <div style="display: flex">
        <div>
          <div class="title">
            <img
              style="width: 40px; height: 31px"
              src="../../../assets/informatization/5.png"
              alt=""
            />
            <div>服务概括</div>
          </div>
          <div class="txt">
            &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp
            依据政企事业单位的需求，依托信息化网络技术，开展信息化资源平台的开发与管理服务，呈现统一的网络平台、统一的数据环境，建设重点业务系统，辅助政企事业单位提高工作效率、决策质量、调控能力、改进政企事业单位的组织结构、业务流程和工作方式，全方位向政企事业单位提供超越时间、空间与部门分隔限制的科学全面的管理服务平台。
          </div>
        </div>
        <div style="width: 220px"></div>
      </div>
      <img
        class="right-img"
        style="width: 190px; hight: 190px"
        src="../../../assets/informatization/8.png"
        alt=""
      />
    </div>
    <div class="right-content">
      <div class="right-middle">
        <div class="middle">
          <div
            style="
              text-align: left;
              font-size: 24px;
              color: rgba(0, 0, 0, 1);
              margin-bottom: 20px;
            "
          >
            智慧人才综合服务平台
          </div>
          <div
            style="
              text-align: left;
              line-height: 23px;
              color: rgba(166, 168, 182, 1);
            "
          >
            平台采用一网管、一网办、一网看的模式，为辖区各类人才和企事业单位宣传人才政策...
          </div>
          <div @click="goGovernment" class="click-txt">
            了解详情<i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div style="width: 20px"></div>
        <div class="middle">
          <div
            style="
              text-align: left;
              font-size: 24px;
              color: rgba(0, 0, 0, 1);
              margin-bottom: 20px;
            "
          >
            社会治理综合平台
          </div>
          <div
            style="
              text-align: left;
              line-height: 23px;
              color: rgba(166, 168, 182, 1);
            "
          >
            平台通过集成信息、优化服务、风险预警及智能决策等功能...
          </div>
          <div @click="goGovernment" class="click-txt">
            了解详情<i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
      <div class="right-bottom">
        <div class="middle">
          <div
            style="
              text-align: left;
              font-size: 24px;
              color: rgba(0, 0, 0, 1);
              margin-bottom: 20px;
            "
          >
            智慧交通监管平台
          </div>
          <div
            style="
              text-align: left;
              line-height: 23px;
              color: rgba(166, 168, 182, 1);
            "
          >
            平台通过集成大数据分析、物联网等技术，实现实时交通监控...
          </div>
          <div @click="goGovernment" class="click-txt">
            了解详情<i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div style="width: 20px"></div>
        <div class="middle">
          <div
            style="
              text-align: left;
              font-size: 24px;
              color: rgba(0, 0, 0, 1);
              margin-bottom: 20px;
            "
          >
            智慧监狱解决方案
          </div>
          <div
            style="
              text-align: left;
              line-height: 23px;
              color: rgba(166, 168, 182, 1);
            "
          >
            以现代科技为驱动，通过智能安全监控、大数据分析预测、物联网设施管理...
          </div>
          <div @click="goGovernment" class="click-txt">
            了解详情<i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
      <div class="right-bottom">
        <div class="middle">
          <div
            style="
              text-align: left;
              font-size: 24px;
              color: rgba(0, 0, 0, 1);
              margin-bottom: 20px;
            "
          >
            国防动员综合业务管理系统
          </div>
          <div
            style="
              text-align: left;
              line-height: 23px;
              color: rgba(166, 168, 182, 1);
            "
          >
            系统围绕“建设前、建设中、建设后”人防工程管理全生命周期...
          </div>
          <div @click="goGovernment" class="click-txt">
            了解详情<i class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div style="width: 20px"></div>
        <div class="middle">
          <div>
            <div
              @click="goGovernment"
              style="
                text-align: center;
                color: rgba(166, 168, 182, 1);
                font-size: 25px;
                padding-top: 45px;
                cursor: pointer;
              "
            >
              更多平台...
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    // 跳转政企信息化服务页面
    goGovernment() {
      this.$router.push("/government").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  width: 856px;
  position: relative;
  background: rgba(247, 250, 255, 1);
  border-radius: 30px;
  padding: 10px 40px 40px 40px;
  margin-bottom: 40px;

  .title {
    display: flex;
    justify-content: center;
    font-size: 24px;
    color: rgba(66, 66, 66, 1);
    margin-bottom: 20px;
  }

  .txt {
    display: flex;
    text-align: left;
    color: rgba(66, 66, 66, 1);
    line-height: 30px;
    font-size: 18px;
  }

  .right-img {
    position: absolute;
    right: -5%;
    top: -80px;
  }
}

.right-content {
  overflow: auto;
  height: 430px;
  width: 960px;
}

.right-middle {
  display: flex;
  justify-content: space-between;
  width: 936px;
}

.middle {
  background: rgba(247, 250, 255, 1);
  border-radius: 30px;
  padding: 20px 40px;
  width: 425px;
  margin-bottom: 40px;
}

.click-txt {
  color: rgba(0, 113, 189, 1);
  text-align: right;
  padding: 10px 0;
  cursor: pointer;
}

.right-bottom {
  display: flex;
  justify-content: space-between;
  width: 936px;
}
</style>
