<template>
  <div class="foot">
    <div class="top">
      <div class="top-title">
        <div class="transverse"></div>
        <div class="top-txt">数造理想教育&nbsp&nbsp&nbsp&nbsp开创智引未来</div>
        <div class="transverse"></div>
      </div>
    </div>
    <div class="bottom">
      <div class="content">
        <div class="foot-content">
          <div class="foot-title">关于我们</div>
          <div>
            <el-popover
              popper-class="custom-popover"
              placement="right"
              trigger="hover"
            >
              <div style="text-align: center">
                <img src="../../assets/foot/5.png" alt="" />
              </div>
              <el-button class="img-button" slot="reference">
                <img src="../../assets/foot/1.png" alt=""
              /></el-button>
            </el-popover>
          </div>
          <div
            style="
              color: rgba(255, 255, 255, 1);
              font-size: 20px;
              margin: 10px 0 10px 0;
            "
          >
            联系我们
          </div>
          <div>
            <div
              style="
                font-size: 14px;
                line-height: 38px;
                color: rgba(153, 153, 153, 1);
                min-width: 250px;
              "
            >
              联系电话: 0816-2606550
            </div>
            <div
              style="
                font-size: 14px;
                line-height: 38px;
                color: rgba(153, 153, 153, 1);
                min-width: 250px;
              "
            >
              联系邮箱: 2104904174@qq.com
            </div>
          </div>
        </div>
        <div
          style="
            border-left: 1px solid rgba(77, 77, 77, 1);
            height: 240px;
            margin: 0 80px;
          "
        ></div>
        <div class="foot-content">
          <div class="foot-title">关于宜本</div>
          <ul>
            <li @click="goIntro">企业简介</li>
            <li @click="goIntro">子公司简介</li>
            <li @click="goIntro">荣誉资质</li>
            <li @click="goIntro">软著证书</li>
            <li @click="goIntro">合作单位</li>
          </ul>
        </div>
        <div class="foot-content">
          <div class="foot-title">信息化产品服务</div>
          <ul>
            <!-- <li @click="goCabs">人才培养大数据分析平台</li> -->
            <li @click="goMilitary">军工信息化服务</li>
            <li @click="goGovernment">政企信息化服务</li>
            <li @click="goOther">其他信息化服务</li>
            <!-- <li @click="goMajor">专业建设质量评估平台</li>
            <li @click="goEducation">教育教学质量评估平台</li>
            <li @click="goTraining">虚拟仿真实训室建设</li>
            <li @click="goWisdom">一体化智慧校园</li>
            <li @click="goPolitical">思政教育智慧平台</li> -->
            <!-- <li @click="goClassroom">课堂教学平台</li>
            <li @click="goCustom">软件定制服务</li>
            <li @click="goMaintain">运维服务</li> -->
          </ul>
        </div>
        <div class="foot-content">
          <div class="foot-title">教育内涵建设</div>
          <ul>
            <li @click="goConnotation">教育应用研究</li>
            <li @click="goConnotation">院校发展咨询</li>

            <li @click="goConnotation">专业顶层规划</li>
            <li @click="goConnotation">教学资源开发</li>
            <li @click="goConnotation">师资队伍培养</li>
            <li @click="goConnotation">产教校企合作</li>
          </ul>
        </div>
      </div>
      <div class="foot-bottom">
        <div>
          Copyright© 2024 绵阳宜本科技有限公司 &nbsp&nbsp&nbsp&nbsp备案号:
          <a href="https://beian.miit.gov.cn/"> 蜀ICP备2022021532号-1</a>
        </div>
        <div class="foot-bottom-right">
          <!-- <div>炎培研究院</div> -->
          <!-- <div class="line">|</div> -->
          <!-- <div>网站地图</div>
          <div class="line">|</div>
          <div>致用户书</div>
          <div class="line">|</div>
          <div>用户协议</div>
          <div class="line">|</div>
          <div>隐私政策</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Foot",
  methods: {
    // 跳转军工页面
    goMilitary() {
      this.$router.push("/military").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },
    // 跳转政企信息化服务页面
    goGovernment() {
      this.$router.push("/government").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },
    // 跳转其他信息化服务页面
    goOther() {
      this.$router.push("/other").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },
    // 跳转教育教学质量评估平台
    goEducation() {
      this.$router.push("/education").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },
    // 跳转专业建设指令评估平台
    goMajor() {
      this.$router.push("/major").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },
    //跳转简介页面
    goIntro() {
      this.$router.push("/intro").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },
    // 跳转智慧校园页面
    goWisdom() {
      this.$router.push("/wisdom").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },
    // 跳转思政页面
    goPolitical() {
      this.$router.push("/political").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },
    // 跳转虚拟仿真实训室页面
    goTraining() {
      this.$router.push("/training").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },
    //跳转CABS页面
    goCabs() {
      this.$router.push("/cabs").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },
    // 跳转虚拟仿真实训室页面
    goTraining() {
      this.$router.push("/training").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },
    // 跳转课堂教学平台
    goClassroom() {
      this.$router.push("/classroom").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },
    // 跳转教育建设内涵
    goConnotation() {
      if (this.$route.path == "/connotation") {
        window.scrollTo({
          top: 0,
          behavior: "smooth", // 平滑滚动效果
        });
      } else {
        this.$router.push("/connotation").catch((err) => {
          if (!err.name === "NavigationDuplicated") {
          }
        });
      }
    },
    // 跳转软件定制服务
    goCustom() {
      this.$router.push("/custom").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },
    // 跳转运维服务
    goMaintain() {
      this.$router.push("/maintain").catch((err) => {
        if (!err.name === "NavigationDuplicated") {
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.img-button {
  background-color: #0b0e12;
  border-color: #0b0e12;
  height: 20px;
  transform: translateX(-20px);
}

.img-button:hover {
  background-color: #0b0e12;
  border-color: #0b0e12;
}

.foot {
  .top {
    padding-top: 100px;
    width: 100%;
    height: 250px;
    background: url("../../assets/foot/4.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .transverse {
      width: 1456px; /* 横线的宽度 */
      height: 5px; /* 横线的高度 */
      background: linear-gradient(
        to right,
        rgba(33, 43, 63, 0),
        rgba(14, 153, 167, 1),
        rgba(33, 43, 63, 0)
      );
      margin: 20px auto;
    }

    .top-txt {
      font-size: 48px;
      color: rgba(255, 255, 255, 1);
      font-weight: 400;
      text-align: center;
    }

    .top-but {
      padding-top: 60px;
      display: flex;
      justify-content: center;
    }
  }
  .bottom {
    background: #0b0e12;
    padding: 80px 0;
  }

  .content {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid rgba(77, 77, 77, 1);
    width: 1356px;
    margin: 0 auto;
    margin-bottom: 20px;
    padding-bottom: 40px;
  }

  .foot-content {
    width: 320px;
    // margin: 0 20px;
    // min-width: 160px;
  }

  .foot-title {
    color: rgba(255, 255, 255, 1);
    font-size: 18px;
    margin-bottom: 20px;
  }

  img {
    height: 30px;
    width: 30px;
  }
}

.foot-content > ul > li:hover {
  color: rgb(6, 154, 168);
  cursor: pointer;
}

ul,
li {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
  color: rgba(153, 153, 153, 1);
  font-size: 14px;
  line-height: 38px;
}

.foot-bottom {
  color: rgba(153, 153, 153, 1);
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  max-width: 1356px;
  margin: 0 auto;

  a {
    color: rgba(153, 153, 153, 1);
    text-decoration: none;
  }

  .foot-bottom-right {
    display: flex;

    .line {
      margin: 0 10px;
    }
  }
}
</style>
